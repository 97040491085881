import { SvgIcon } from '@components/SvgIcon';
import { Tooltip } from '@components/Tooltip';
import { IoFilterSharp, IoSearchOutline } from 'react-icons/io5';
import Filters from '../components/Filters';
import useSearchForm from '../hooks/useSearchForm';

function Home() {
  const {
    formData,
    setFormData,
    handleChangeQuery,
    handleChangeToggle,
    isQueryValid,
    go,
    inputError,
    showFilters,
    setShowFilters,
  } = useSearchForm(false, '/search', '/search');

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.target.blur();
    }
    if (event.key === 'Enter' && isQueryValid()) {
      event.preventDefault();
      go(event);
    }
  };

  return (
    <div className="card-elevation flex min-h-[80vh] w-full flex-col items-center justify-start py-20">
      <div className="flex w-[300px] flex-col items-center justify-center gap-3 lg:w-[768px]">
        {/* Title */}
        <h2 className="text-3xl font-bold lg:text-5xl">Welcome to YunoAI</h2>
        <p className="mb-10 px-5 pt-3 text-center text-sm lg:px-0 lg:text-lg">
          Find, understand & track the companies that matter to you
        </p>

        {/* Form */}
        <form className="items-left flex w-full flex-col justify-center" onSubmit={go}>
          {/* Query */}
          <p className="mb-4 ml-3 font-primary font-bold lg:text-xl">Company Search</p>
          <div></div>
          <div className="flex w-full items-center justify-between gap-4">
            <label
              className={
                'input flex w-full items-center gap-2 rounded-full border border-[#7D8FB3] bg-base-200 p-6 text-lg outline-none lg:py-8' +
                `${inputError && 'border-1 w-full border-error'}`
              }
            >
              <IoSearchOutline size={20} />
              <input
                type="text"
                placeholder="Insert your query..."
                className="grow bg-base-200"
                name="query"
                value={formData.query}
                maxLength={500}
                autoComplete="off"
                onBlur={isQueryValid}
                onChange={handleChangeQuery}
                onKeyDown={handleKeyDown}
              />
            </label>
            {/* Show/Hide Filters */}
            <button
              type="button"
              onClick={() => setShowFilters(!showFilters)}
              className="btn btn-circle tooltip tooltip-primary flex items-center justify-center rounded-full border border-gray-200 bg-base-200 shadow-md active:shadow-inner hover:bg-base-200"
              data-tip="Filters"
            >
              <IoFilterSharp size={20} />
            </button>
          </div>

          {inputError && (
            <div className="items-left pt-1">
              <p className="text-left text-sm text-red-500">{inputError}</p>{' '}
            </div>
          )}

          {/* GenAI */}
          <div className="justify-left mb-10 mt-4 flex items-center gap-2">
            <span className="text-md font-semibold">AI Search</span>
            <div className="form-control">
              <label className="label flex w-fit cursor-pointer gap-4">
                <input
                  type="checkbox"
                  className={`toggle ${
                    formData.type
                      ? 'border-neutral bg-neutral hover:bg-neutral hover:opacity-80'
                      : 'border-primary-600 bg-primary-600 hover:bg-primary-600 hover:opacity-80'
                  }`}
                  onChange={handleChangeToggle}
                  checked={formData.type}
                  data-label="type"
                />
              </label>
            </div>
            <span className="font-regular text-md">Search by name</span>
            <Tooltip content="nameAndAISearchesTooltip" side="right" align="start">
              <SvgIcon name="HelpCircleGradientIcon" className="size-6" />
            </Tooltip>
          </div>

          {/* <div className="divider"></div> */}

          {showFilters && <Filters formData={formData} setFormData={setFormData} isHome={true} />}
        </form>
      </div>
    </div>
  );
}

export default Home;

import { Button } from '@components/Button';
import { SIGNAL_SORTING_OPTIONS, SIGNAL_TYPES_OPTIONS } from '@global/constants/options';
import { FC } from 'react';
import { SelectFilter } from './SelectFilter';
import { ShowDuplicatesFilter } from './parts';

export const Filters: FC = () => (
  <>
    <div className="flex gap-2 *:text-lg *:font-normal *:capitalize">
      <Button text="all" intent={'accent'} size={'large'} />
      {/* <Button disabled text="strategicSignals" intent={'empty'} size={'large'} title="Coming soon" />
      <Button disabled text="stealthStartups" intent={'empty'} size={'large'} title="Coming soon" /> */}
    </div>
    <div className="flex h-12 gap-[14px]">
      <SelectFilter field="type" options={SIGNAL_TYPES_OPTIONS} placeholder="typeAll" />
      <SelectFilter field="sorting" options={SIGNAL_SORTING_OPTIONS} />
    </div>
    <ShowDuplicatesFilter />
  </>
);
